<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4 4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6H5.87876L9.45653 22.4084C9.16726 22.8697 9 23.4153 9 24C9 25.6569 10.3431 27 12 27C13.3062 27 14.4175 26.1652 14.8293 25H20.1707C20.5825 26.1652 21.6938 27 23 27C24.6569 27 26 25.6569 26 24C26 22.3431 24.6569 21 23 21C21.6938 21 20.5825 21.8348 20.1707 23H14.8293C14.4175 21.8348 13.3062 21 12 21C11.7298 21 11.4679 21.0357 11.2188 21.1027L10.9784 20H24.8517C25.5816 20 26.2056 19.4746 26.3298 18.7553L27.8843 9.75531C28.0427 8.8384 27.3367 8 26.4062 8H8.36184L7.66125 4.78696C7.56108 4.32756 7.1544 4 6.68421 4H4ZM22 24C22 24.5523 22.4477 25 23 25C23.5523 25 24 24.5523 24 24C24 23.4477 23.5523 23 23 23C22.4477 23 22 23.4477 22 24ZM8.79793 10L10.5423 18H24.4306L25.8125 10H8.79793ZM12 23C11.4477 23 11 23.4477 11 24C11 24.5523 11.4477 25 12 25C12.5523 25 13 24.5523 13 24C13 23.4477 12.5523 23 12 23Z"
		></path>
	</svg>
</template>

<script>
export default defineComponent({
	name: "IconCart",
});
</script>
